import React, { useContext } from 'react';
import { Component } from 'react';
import { UserContext } from '../providers/UserProvider';
import { navigate } from '@reach/router';
import { auth } from '../firebase/firebase';

//routing
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Home from './reserved_area/Home';
import Appuntamenti from './reserved_area/Appuntamenti';
import Multimedia from './reserved_area/Multimedia';

import { NavDropdown, Navbar, Nav } from 'react-bootstrap';

// npm install --save-dev @iconify/react @iconify/icons-ant-design
import { Icon, InlineIcon } from '@iconify/react';
import userOutlined from '@iconify/icons-ant-design/user-outlined';
import '../styles/profilepage.css';
import logo from '../img/logo.png';

const ProfilePage = () => {
	const user = useContext(UserContext);
	const { photoURL, displayName, email } = user;
	// console.log(user);

	return (
		<div>
			<Router>
				<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
					<Navbar.Brand href="#home">Area Riservata</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							<Nav.Link href="#features">Consulenza</Nav.Link>
							<Nav.Link href="#pricing">Multimedia</Nav.Link>
							<NavDropdown title="Attività" id="collasible-nav-dropdown">
								<NavDropdown.Item href="#action/3.1">
									Implementazione software
								</NavDropdown.Item>
								<NavDropdown.Item href="#action/3.2">
									Storage documenti
								</NavDropdown.Item>
								<NavDropdown.Item href="#action/3.3">
									Smart working
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href="#action/3.4">
									Certificati
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
						<Nav>
							<Nav.Link href="https://www.ingegneriabernasconi.it/">
								<img
									className="img_login"
									src={logo}
									alt="logo"
									style={{
										maxWidth: '50px',
										height: 'auto',

										filter: 'drop-shadow 8px 8px 8px',
									}}
								/>
							</Nav.Link>
							<Nav.Link eventKey={2} href="#memes">
								<button
									onClick={() => {
										auth.signOut();
									}}
								>
									Sign out
								</button>
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</Router>
			<div className="mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
				<div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
					<div className="md:pl-4">
						<h2 className="testo_user">
							Gentile utente {displayName} - {email} ha eseguito l'accesso
							nell'Area Riservata del sito.
						</h2>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilePage;

{
	/* <Router>
	<h2>Welcome to React Router Tutorial</h2>
	<nav className="navbar navbar-expand-lg navbar-light bg-light">
		<ul className="navbar-nav mr-auto">
			<li>
				<Link to={'/'} className="nav-link">
					{' '}
					Home{' '}
				</Link>
			</li>
			<li>
				<Link to={'/Appuntamenti'} className="nav-link">
					Appuntamenti
				</Link>
			</li>
			<li>
				<Link to={'/about'} className="nav-link">
					Multimedia
				</Link>
			</li>
		</ul>
	</nav>
	<hr />
	<Switch>
		<Route exact path="/" component={Home} />
		<Route path="/appuntamenti" component={Appuntamenti} />
		<Route path="/multimedia" component={Multimedia} />
	</Switch>
</Router>; */
}
