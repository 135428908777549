import React, { useState } from 'react';
import { Link } from '@reach/router';
import { signInWithGoogle } from '../firebase/firebase';
import { auth } from '../firebase/firebase';

import '../styles/signin.css';
import logo from '../img/logo.png';

const SignIn = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);

	const signInWithEmailAndPasswordHandler = (event, email, password) => {
		event.preventDefault();
		auth.signInWithEmailAndPassword(email, password).catch((error) => {
			setError('Errore accesso non autorizzato');
			console.error('Errore accesso non autorizzato', error);
		});
	};

	const onChangeHandler = (event) => {
		const { name, value } = event.currentTarget;

		if (name === 'userEmail') {
			setEmail(value);
		} else if (name === 'userPassword') {
			setPassword(value);
		}
	};

	return (
		<div id="home" className="intro route bg-image background">
			<div id="stars" />
			<div id="stars2" />
			<div id="stars3" />
			<div class="container" style={{ marginTop: '9vh' }}>
				<div class="row align-items">
					<div
						class="col-sm-12 col align-self-center"
						style={{ placeContent: 'center', textAlign: '-webkit-center' }}
					>
						<a href="https://www.ingegneriabernasconi.it/">
							<img
								className="img_login"
								src={logo}
								alt="logo"
								style={{
									maxWidth: '100px',
									height: 'auto',

									filter: 'drop-shadow 8px 8px 8px',
								}}
							/>
						</a>
						<div className="login_box">
							<div class="form-header deep-blue-gradient rounded">
								<h3 class="my-3">
									<i class="fa fa-lock"></i> Login:
								</h3>
							</div>
							<form className="">
								<label htmlFor="userEmail" className="block">
									Email:
								</label>
								<input
									type="email"
									className="my-1 p-1 w-full"
									name="userEmail"
									value={email}
									placeholder="Email"
									id="userEmail"
									onChange={(event) => onChangeHandler(event)}
								/>
								<label htmlFor="userPassword" className="block">
									Password:
								</label>
								<input
									type="password"
									className="mt-1 mb-3 p-1 w-full"
									name="userPassword"
									value={password}
									placeholder="Password"
									id="userPassword"
									onChange={(event) => onChangeHandler(event)}
								/>
								<button
									className="signin_button"
									style={{ borderRadius: '0.25rem' }}
									onClick={(event) => {
										signInWithEmailAndPasswordHandler(event, email, password);
									}}
								>
									Sign in
								</button>
								{error !== null && <div className="box_errore">{error}</div>}
							</form>
							<p className="text-center my-3">or</p>
							<button
								className="signin_button"
								style={{ borderRadius: '0.25rem' }}
								onClick={() => {
									signInWithGoogle();
								}}
							>
								Sign in with Google
							</button>
							<p className="text-center my-3">
								Don't have an account?{' '}
								<Link to="signUp" className="text-blue-500 hover:text-blue-600">
									Sign up here
								</Link>{' '}
								<br />{' '}
								<Link
									to="passwordReset"
									className="text-blue-500 hover:text-blue-600"
								>
									Forgot Password?
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
