import React, { useContext, useState } from 'react';
import { Link } from '@reach/router';
import {
	auth,
	signInWithGoogle,
	generateUserDocument,
} from '../firebase/firebase';

import '../styles/signin.css';
import logo from '../img/logo.png';

const SignUp = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [displayName, setDisplayName] = useState('');
	const [error, setError] = useState(null);

	const createUserWithEmailAndPasswordHandler = async (
		event,
		email,
		password
	) => {
		event.preventDefault();
		try {
			const { user } = await auth.createUserWithEmailAndPassword(
				email,
				password
			);
			generateUserDocument(user, { displayName });
		} catch (error) {
			setError('Errore registrarsi con email e password');
		}

		setEmail('');
		setPassword('');
		setDisplayName('');
	};

	const onChangeHandler = (event) => {
		const { name, value } = event.currentTarget;

		if (name === 'userEmail') {
			setEmail(value);
		} else if (name === 'userPassword') {
			setPassword(value);
		} else if (name === 'displayName') {
			setDisplayName(value);
		}
	};

	return (
		<div id="home" className="intro route bg-image background">
			<div id="stars" />
			<div id="stars2" />
			<div id="stars3" />
			<div class="container" style={{ marginTop: '9vh' }}>
				<div class="row align-items">
					<div
						class="col-sm-12 col align-self-center"
						style={{ placeContent: 'center', textAlign: '-webkit-center' }}
					>
						<a href="https://www.ingegneriabernasconi.it/">
							<img
								className="img_login"
								src={logo}
								alt="logo"
								style={{
									maxWidth: '100px',
									height: 'auto',

									filter: 'drop-shadow 8px 8px 8px',
								}}
							/>
						</a>
						<div className="login_box">
							<div class="form-header deep-blue-gradient rounded">
								<h3 class="my-3">
									<i class="fa fa-lock"></i> Sign Up:
								</h3>
							</div>

							<form className="">
								<label htmlFor="displayName" className="block">
									Display Name:
								</label>
								<input
									type="text"
									className="my-1 p-1 w-full "
									name="displayName"
									value={displayName}
									placeholder="Name"
									id="displayName"
									onChange={(event) => onChangeHandler(event)}
								/>
								<label htmlFor="userEmail" className="block">
									Email:
								</label>
								<input
									type="email"
									className="my-1 p-1 w-full"
									name="userEmail"
									value={email}
									placeholder="Email"
									id="userEmail"
									onChange={(event) => onChangeHandler(event)}
								/>
								<label htmlFor="userPassword" className="block">
									Password:
								</label>
								<input
									type="password"
									className="mt-1 mb-3 p-1 w-full"
									name="userPassword"
									value={password}
									placeholder="Password"
									id="userPassword"
									onChange={(event) => onChangeHandler(event)}
								/>
								<button
									className="signin_button"
									onClick={(event) => {
										createUserWithEmailAndPasswordHandler(
											event,
											email,
											password
										);
									}}
								>
									Sign up
								</button>
								{error !== null && <div className="box_errore">{error}</div>}
							</form>
							<p className="text-center my-3">or</p>
							<button
								onClick={() => {
									try {
										signInWithGoogle();
									} catch (error) {
										console.error('Error signing in with Google', error);
									}
								}}
								className="signin_button"
							>
								Sign In with Google
							</button>
							<p className="text-center my-3">
								Already have an account?{' '}
								<Link to="/" className="text-blue-500 hover:text-blue-600">
									Sign in here
								</Link>{' '}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
