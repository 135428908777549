import React, { useState, useContext } from 'react';
import { auth } from '../firebase/firebase';
import { UserContext } from '../providers/UserProvider';
import { Link } from '@reach/router';

import '../styles/signin.css';
import logo from '../img/logo.png';

const PasswordReset = () => {
	const [email, setEmail] = useState('');
	const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
	const [error, setError] = useState(null);

	const onChangeHandler = (event) => {
		const { name, value } = event.currentTarget;

		if (name === 'userEmail') {
			setEmail(value);
		}
	};

	const sendResetEmail = (event) => {
		event.preventDefault();
		auth
			.sendPasswordResetEmail(email)
			.then(() => {
				setEmailHasBeenSent(true);
				setTimeout(() => {
					setEmailHasBeenSent(false);
				}, 3000);
			})
			.catch(() => {
				setError('Error resetting password');
			});
	};
	return (
		<div id="home" className="intro route bg-image background">
			<div id="stars" />
			<div id="stars2" />
			<div id="stars3" />
			<div class="container" style={{ marginTop: '9vh' }}>
				<div class="row align-items">
					<div
						class="col-sm-12 col align-self-center"
						style={{ placeContent: 'center', textAlign: '-webkit-center' }}
					>
						<a href="https://www.ingegneriabernasconi.it/">
							<img
								className="img_login"
								src={logo}
								alt="logo"
								style={{
									maxWidth: '100px',
									height: 'auto',

									filter: 'drop-shadow 8px 8px 8px',
								}}
							/>
						</a>
						<div className="login_box">
							<div class="form-header deep-blue-gradient rounded">
								<h3 class="my-3">
									<i class="fa fa-lock"></i> Reset password:
								</h3>
							</div>

							<form action="">
								{emailHasBeenSent && (
									<div className="py-3 bg-green-400 w-full text-white text-center mb-3">
										An email has been sent to you!
									</div>
								)}
								{error !== null && (
									<div className="py-3 bg-red-600 w-full text-white text-center mb-3">
										{error}
									</div>
								)}
								<label htmlFor="userEmail" className="w-full block">
									Email:
								</label>
								<input
									type="email"
									name="userEmail"
									id="resetEmail"
									value={email}
									placeholder="Email"
									onChange={onChangeHandler}
									className="mb-3 w-full px-1 py-2"
								/>
								<button
									className="signin_button"
									onClick={(event) => {
										sendResetEmail(event);
									}}
								>
									Send me a reset link
								</button>
							</form>
							<br />

							<Link to="/" className="text-blue-500 hover:text-blue-600">
								&larr; back to sign in page
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PasswordReset;
