import React, { useContext } from 'react';
import { Router } from '@reach/router';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Application from './components/Application';
import UserProvider from './providers/UserProvider';
import ProfilePage from './components/ProfilePage';
import { UserContext } from './providers/UserProvider';
import Navbar from './components/navbar.jsx';
import Container from '@material-ui/core/Container';

import './components/stars.scss';

//import css in order
import 'normalize.css';
import './styles/animate.css';
import 'bootstrap/dist/css/bootstrap.css';
import './img/icons/css/ionicons.css';
import './img/font-awesome/css/font-awesome.css';
import 'lightbox2/dist/css/lightbox.min.css';
import './styles/style.css';

//import js libraries
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './libs/easing.js';
import 'lightbox2/dist/js/lightbox.min.js';

function App() {
	return (
		<UserProvider>
			{/* <Navbar /> */}
			{/* <Container> */}
			<Application />
			{/* </Container> */}
		</UserProvider>
	);
}

export default App;
